// const btn = document.getElementById('js-burger-btn');
// const menu = document.getElementById('js-navbar');
//
// btn.addEventListener('click', () => {
// 	menu.classList.toggle('is-navbar-open');
// });


// function closeAppBanner() {
//     const appBanner = document.getElementById('c-app-banner');
//     const header = document.querySelector('.c-header');

//     appBanner.style.display = 'none';
//     header.style.top = '0';
// }

// window.onload = function () {
//     setTimeout(() => {
//         const appBanner = document.getElementById('c-app-banner');
//         const header = document.querySelector('.c-header');

//         appBanner.style.display = 'flex';
//         header.style.top = '48px';
//     }, 3000);
// };

function closeAppBanner() {
    const appBanner = document.getElementById('c-app-banner');
    const header = document.querySelector('.c-header');

    if (window.innerWidth <= 1024) {
        appBanner.style.display = 'none';
        header.style.top = '0';
    }
}

window.onload = function () {
    if (window.innerWidth <= 1024) {
        setTimeout(() => {
            const appBanner = document.getElementById('c-app-banner');
            const header = document.querySelector('.c-header');

            appBanner.style.display = 'flex';
            header.style.top = '48px';
        }, 3000);
    }
};


// document.getElementById('langs-switcher').addEventListener('click', function (event) {
//     event.stopPropagation();
//     document.getElementById('langs-menu').classList.toggle('c-header__langs_menu_active');
// });

// document.addEventListener('click', function (event) {
//     const menu = document.getElementById('languageMenu');
//     if (!document.getElementById('langs-switcher').contains(event.target)) {
//         menu.classList.remove('c-header__langs_menu_active');
//     }
// });

document.getElementById('langs-switcher').addEventListener('click', function (event) {
    event.stopPropagation();
    document.getElementById('langs-menu').classList.toggle('c-header__langs_menu_active');
});

document.addEventListener('click', function (event) {
    const menu = document.getElementById('langs-menu'); // тут виправлено
    if (menu && !document.getElementById('langs-switcher').contains(event.target)) {
        menu.classList.remove('c-header__langs_menu_active');
    }
});


const items = document.getElementById("c-winners__items");
const itemWidth = 304; 
const visibleItems = 5; 
const totalItems = items.children.length; 
let currentIndex = 0;

for (let i = 0; i < visibleItems; i++) {
    const clone = items.children[i].cloneNode(true);
    items.appendChild(clone); 
}

function scrollItems() {
    currentIndex++;

    if (currentIndex > totalItems) {
        currentIndex = 1; 
        items.style.transition = "none";
        items.style.transform = `translateY(0px)`; 
        setTimeout(() => {
            items.style.transition = "transform 0.5s ease"; 
            items.style.transform = `translateX(-${currentIndex * itemWidth
                }px)`;
        }, 50);
    } else { 
        items.style.transform = `translateX(-${currentIndex * itemWidth
            }px)`;
    }
}

setInterval(scrollItems, 6000); 

if (window.innerWidth >= 1024) {

    window.onload = function () {
        var popup = document.getElementById("c-popup");
        var span = document.getElementsByClassName("c-popup-close")[0];

        setTimeout(function () {
            popup.style.display = "block";
            document.body.classList.add("overflow-hidden");
        }, 3000);

        span.onclick = function () {
            popup.style.display = "none";
            document.body.classList.remove("overflow-hidden");
        };

        window.onclick = function (event) {
            if (event.target == popup) {
                popup.style.display = "none";
                document.body.classList.remove("overflow-hidden");
            }
        };
    };

}